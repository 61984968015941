import { ReactNode } from 'react';

import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import MobileHeader from '../components/MobileHeader/MobileHeader';
import MobileNav from '../components/MobileNav/MobileNav';
interface Props {
  children: ReactNode;
}

const SiteLayout = ({ children, ...props }: Props) => {
  return (
    <>
      {/* <StageMessage /> */}
      <Header />
      {/* <Navbar /> */}
      <MobileHeader />
      {children}
      <Footer />
      <MobileNav />
    </>
  );
};

export default SiteLayout;
