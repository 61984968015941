import { useMemo } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';

function usePlusMembershipFeatures() {
  const { messages } = useLocale();
  const {
    plus_up_to_10_off_on_msg,
    plus_access_to_exclusive_sale_events_msg,
    plus_priority_preorder_fulfillment_msg,
    plus_priority_support_msg,
  } = messages || {};

  const features = useMemo(() => {
    return [
      plus_up_to_10_off_on_msg ||
        'Up to 10% OFF on Games, Gift Cards, DLCs and more',
      plus_access_to_exclusive_sale_events_msg ||
        'Access to exclusive sale events and promotions',
      plus_priority_preorder_fulfillment_msg ||
        'Priority pre-order fulfillment',
      plus_priority_support_msg || 'Priority support',
    ];
  }, [
    plus_access_to_exclusive_sale_events_msg,
    plus_priority_preorder_fulfillment_msg,
    plus_priority_support_msg,
    plus_up_to_10_off_on_msg,
  ]);

  const memodValue = useMemo(() => {
    return { features };
  }, [features]);

  return memodValue;
}

export default usePlusMembershipFeatures;
