import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { WIDTH } from '../../../constants/screenResolution';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 100px;
  overflow: hidden;
  background: #0c0c0c;
`;

export const LandingPageMainSectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1728px;
  margin: 0 auto;
  width: 100%;
`;

export const RadialGlowBackground = styled.div`
  display: flex;

  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);

  background: radial-gradient(
    62.05% 122.72% at 50% -48.1%,
    #480f91 0%,
    rgba(12, 12, 12, 0) 100%
  );
  height: 600px;
  width: 100vw;
  pointer-events: none;
  z-index: 0;
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 300px;
    top: 0%;
  }
`;

export const PlusIllustration1 = styled.div`
  position: absolute;
  top: -50px;
  left: -50px;
  z-index: 1;
`;

export const PlusIllustration2 = styled.div`
  position: absolute;
  bottom: -50px;
  right: -50px;
  z-index: 1;
`;

export const LandingPageMainSectionTitle = styled.div`
  display: flex;
  margin-top: 64px;
  z-index: 1;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 48px;
  }
`;

export const LandingPageMainSectionCaption = styled.div`
  p {
    font: 1.125rem/1.375rem Onest-Semibold;
    color: #e1e1e1;
    text-align: center;
  }
  margin: 48px auto 40px;
  z-index: 1;
  padding: 0 32px;
  @media (max-width: ${WIDTH.mobileMax}) {
    p {
      font: 0.875rem/1.125rem Onest-Semibold;
      color: #e1e1e1;
    }
  }
`;

export const LandingPageMainSectionBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  margin: 20px auto 88px;
  z-index: 1;
`;

export const LandingPageMainSectionBodyMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  margin: 0 auto 88px;
  z-index: 1;
`;

export const LandingPageMainSectionTncText = styled.div`
  font: 0.875rem/1.125rem Onest-Medium;
  color: #ffffff;
  padding: 0 16px;
  text-align: center;
  margin-top: 16px;
  max-width: 804px;
  a {
    text-decoration: underline;
    color: #4885ff;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 24px;
    font: 0.75rem/1rem Onest-Medium;
  }
`;

export const SubscriptionCardWrapper = styled.div`
  position: relative;
`;

interface SubscriptionCardContainerI {
  borderColor: string;
  backgroundColor: string;
}

export const SubscriptionCardContainer = styled.div<SubscriptionCardContainerI>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  padding: 16px;
  border: 1.5px solid ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  max-width: 324px;
  transition: transform 250ms ease;
  overflow: hidden;
  :hover {
    transform: scale(102%);
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    max-width: 100%;
  }
`;

export const YouSaveBadge = styled.div<{ membershipType: string }>`
  display: flex;
  position: absolute;
  height: 32px;
  align-items: center;
  top: -16px;
  right: 32px;
  border-radius: 8px;
  padding: 4px 8px;
  font: 0.75rem/1rem Onest-Bold;
  text-transform: uppercase;
  color: #fff;
  border: 1.5px solid
    ${(props) => (props.membershipType === 'yearly' ? '#FFE34F' : '#9E60EC')};
  background: ${(props) =>
    props.membershipType === 'quarterly'
      ? 'linear-gradient(104.04deg, #AE2FEA 0%, #6800CF 100%)'
      : 'linear-gradient(104.04deg, #E6C102 0%, #B69900 100%)'};

  border-radius: 16px;
  padding: 8px 16px;
  width: fit-content;
  z-index: 1;
`;

export const SubscriptionCardTitle = styled.div`
  h1 {
    margin: 0;
    padding: 0;
    font: 1.5rem/1.75rem Onest-Bold;
    color: #ffffff;
    text-align: center;
    margin-top: 12px;
  }
`;

export const SubscriptionCardStylishDivider = styled.div<{
  membershipType: string;
}>`
  height: 1.5px;
  width: 70%;
  margin: 16px auto;
  background: ${({ membershipType }) =>
    membershipType === 'yearly'
      ? 'linear-gradient(90deg, rgba(255, 227, 79, 0) 0%, #FFE34F 50%, rgba(255, 227, 79, 0) 100%)'
      : 'linear-gradient(90deg, rgba(158, 96, 236, 0) 0%, #9E60EC 50%, rgba(158, 96, 236, 0) 100%)'};
`;

export const SubscriptionCardFeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubscriptionCardFeatureListItem = styled.div`
  max-width: 324px;
  display: flex;
  gap: 12px;
  align-items: center;
  p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: #ffffff;
  }
  margin-bottom: 20px;
`;

export const SubscriptionCardDivider = styled.div<{ color: string }>`
  height: 1px;
  width: 100%;
  background: ${(props) => props.color}4a;
`;

export const BillingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin: 16px 0;
`;

export const BillingDetailsPrice = styled.div`
  font: 1.75rem/1.75rem Onest-Bold;
  color: #ffffff;

  span {
    padding-left: 6px;
    font: 1.125rem/1.125rem Onest-Bold;
    color: #ffffff;
  }

  s {
    color: #818181;
    font: 1rem/1.25rem Onest-Medium;
    margin-right: 8px;
  }
`;

export const BillingDetailsCaption = styled.div`
  display: flex;
  font: 0.75rem/1rem Onest-Medium;
  color: #9a9a9a;
  text-align: center;
`;

export const WhatDoYouGetSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 180px auto 0;
  gap: 80px;
  width: 100%;
  padding: 0 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 60px auto 16px;
    gap: 48px;
  }
`;

export const WhatDoYouGetSectionTitle = styled.div`
  font: 2.5rem/2.75rem Onest-Bold;
  color: #fff;
  display: flex;
  text-align: center;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

export const WhatDoYouGetSectionBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 auto 88px;
  max-width: 936px;
  width: 100%;

  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0 auto 8px;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

export const WhatDoYouGetSectionCard = styled.div`
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
  justify-content: center;
  gap: 12px;
  max-width: 478px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #353535;
  background-color: #171717;
  width: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 12px;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
`;

export const WhatDoYouGetSectionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  flex: 1;
  h3 {
    margin: 0;
    font: 1rem/1.25rem Onest-Bold;
    color: #fff;
  }
  p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: #9a9a9a;
  }
`;

export const GetExtraDiscountWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  min-height: 382px;
  width: 100%;
  overflow: hidden;
`;

export const GetExtraDiscountLeft = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  background: #171717;
  width: 100%;
  padding: 0 80px;
`;

export const GetExtraDiscountRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  background: linear-gradient(101.06deg, #802cec 28%, #a866fb 80%);
  width: 100%;
  padding: 0 40px 0 80px;
`;

export const GetExtraDiscountTitle = styled.div`
  font: 2.5rem/2.75rem Onest-Bold;
  color: #fff;
  width: 502px;
`;

export const ExtraDiscountJoinNowButton = styled.div`
  width: 502px;
  > button {
    min-width: 180px;
    width: fit-content;
    background: ${COLORS.drifflePlus};
    :hover {
      background: ${COLORS.drifflePlus}ee;
    }
  }
`;

export const GetExtraDiscountRightTitle = styled.div`
  font: 2rem/2.25rem Onest-Bold;
  text-align: left;
  color: #fff;
`;

export const GetExtraDiscountRightBodyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GetExtraDiscountRightBodyListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    font: 1rem/1.25rem Onest-Medium;
    color: #fff;
  }
`;

export const GetExtraDiscountLeftIllustration = styled.div`
  display: flex;
  position: absolute;
  top: -50px;
  left: -40px;
  z-index: 1;
`;

export const FAQSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  min-height: 382px;
  width: 100%;
  overflow: hidden;
  padding: 160px 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 40px 16px;
  }
`;

export const FAQContainer = styled.div`
  max-width: 1132px;
  margin: 0 auto;
  & h2 {
    font: 2.5rem/2.75rem Onest-Bold;
    margin: 0;
    color: #fff;
    text-align: center;
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    & h2 {
      font: 1.5rem/1.75rem Onest-Bold;
      margin: 0;
      text-align: center;
    }
  }
`;

export const FAQsSection = styled.div`
  margin-top: 52px;
  & > div {
    border-bottom: 1px solid #353535;
    padding-bottom: 24px;
    padding-top: 24px;
  }

  & > div:first-of-type {
    padding-top: 0px;
  }

  & > div:last-of-type {
    padding-bottom: 0px;
    border-bottom: 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 35px;
    & > div {
      padding-bottom: 12px;
      padding-top: 10px;
    }
  }
`;

export const AccordionContainer = styled.div`
  & p {
    font-family: Onest-Medium;
  }
  & div {
    font-family: Onest-Medium;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  cursor: pointer;
  gap: 12px;
  p {
    font: 1rem/1.25rem Onest-Medium;
    color: #fff;
  }
`;

export const AccordionContent = styled.div`
  font: 1rem/1.25rem Onest-Medium;
  color: #808080;
  p {
    margin: 0 0 8px;
    padding: 0;
    font: 1rem/1.25rem Onest-Medium;
  }
  ul {
    list-style-type: disc;
    padding-left: 20px;
    li {
      margin-bottom: 8px;
      font: 0.875rem/1.125rem Onest-Medium;
    }
  }
`;

export const SubscriptionCardMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto 32px;
`;

export const SubscriptionCardMobileContainer = styled.div<{
  selected: boolean;
}>`
  display: flex;
  padding: 16px;
  gap: 16px;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  border: 1.5px solid
    ${(props) => (props.selected ? COLORS.drifflePlus : '#353535')};
  background: #171717;
  background: ${(props) => (props.selected ? '#2E1C6133' : 'transparent')};
`;

export const SubscriptionCardMobileHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubscriptionCardMobileHeaderValue = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  p {
    font: 1.25rem/1.5rem Onest-SemiBold;
    color: #ffffff;
  }
  span {
    font: 0.75rem/1rem Onest-Medium;
    color: #808080;
  }
`;

export const SubscriptionCardMobileHeaderTitle = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  font: 1.125rem/1.375rem Onest-Semibold;
  color: #ffffff;
`;

export const MembershipSelectInputRadio = styled.input<{
  fillColor: string | null;
}>`
  height: 20px;
  width: 20px;
  appearance: none;
  border: 2px solid
    ${(props) => (props.theme.mode === 'dark' ? '#444444' : '#d9d9d9')};
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    border-color: ${(props) =>
      props.fillColor ? props.fillColor : props.theme.palette.primary.main};
  }

  &:before {
    content: '';
    height: 12px; /* Adjust the size for the inner white circle */
    width: 12px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.checked
        ? props.fillColor
          ? props.fillColor
          : props.theme.palette.primary.main
        : 'transparent'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
  }
  &:checked:before {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const SubscriptionCardMobileBodyText = styled.div`
  color: #9a9a9a;
  font: 0.875rem/1.125rem Onest-Medium;
`;

export const BenefitsOfPlusWrapper = styled.div`
  padding: 0 16px;
  width: 100%;
`;

export const BenefitsOfPlusContainer = styled.div`
  border-radius: 8px;
  border: 1.5px solid ${COLORS.drifflePlus};
  padding: 20px;
  width: 100%;
`;

export const BenefitsOfPlusContainerTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font: 1.25rem/1.5rem Onest-Semibold;
  color: #ffffff;
`;

export const BenefitsOfPlusContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const BenefitsOfPlusContainerContentItem = styled.div`
  display: flex;
  gap: 12px;
  font: 0.875rem/1.125rem Onest-Medium;
  color: #ffffff;
`;

export const DrifflePlusSellCardWrapper = styled.div`
  display: flex;
  padding: 16px;
`;

export const DrifflePlusSellCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1.5px solid ${COLORS.drifflePlus};
  background: ${(props) =>
    props.theme.mode === 'dark' ? ' #2e1c6133' : 'transparent'};
  width: 100%;
`;

export const DrifflePlusSellCardLogoImage = styled.div`
  height: 30px;
  cursor: pointer;
  min-width: 172px;
  width: 172px;
  position: relative;
  margin: 0 auto;
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 24px;
    min-width: 136px;
    width: 136px;
  }
`;

export const DrifflePlusSellCardCaption = styled.div`
  text-align: center;
  font: 0.875rem/1.125rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const DrifflePlusSellCardBodyList = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  gap: 8px;
`;

export const DrifflePlusSellCardBodyListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    font: 0.75rem/1rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const DrifflePlusSellCardJoinNowButton = styled.div`
  width: 100%;
  > button {
    width: 100%;
    background: ${COLORS.drifflePlus};
    :hover {
      background: ${COLORS.drifflePlus}ee;
    }
  }
`;

export const LearnMoreText = styled.div`
  text-align: center;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => COLORS.drifflePlusText};
`;

export const AlreadyPlusMemberCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-width: 832px;
  width: 100%;
  margin: 80px auto;
  padding: 0 16px;
`;

export const AlreadyPlusMemberCardTitle = styled.div`
  font: 1.5rem/1.75rem Onest-Bold;
  color: #fff;
  text-align: center;
  z-index: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 1.25rem/1.5rem Onest-Bold;
  }
`;

export const AlreadyPlusMemberCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  width: 100%;
`;

export const AlreadyPlusMemberCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  align-items: center;
  border: 2px solid ${COLORS.drifflePlus};
  border-radius: 8px 8px 0 0;
  // border-bottom: none;
  justify-content: center;
  // background: radial-gradient(
  //   72.03% 130.06% at 42.33% 0%,
  //   #0c0c0c 47.55%,
  //   #7626dc 100%
  // );
  p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: #fff;
    text-align: center;
  }
  h6 {
    text-align: center;
    font: 1.25rem/1.5rem Onest-SemiBold;
    color: #fff;
    span {
      font: 1.25rem/1.5rem Onest-Bold;
      color: ${COLORS.drifflePlus};
    }
  }
`;

export const AlreadyPlusMemberCardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: radial-gradient(100% 100% at 0% 0%, #b67efd 0%, #7626dc 68.08%);
  height: 40px;
  border-radius: 0 0 8px 8px;
  p {
    font: 0.75rem/1rem Onest-Medium;
    color: #fff;
  }
`;

export const MembershipStatusTile = styled.div`
  background: linear-gradient(
    95.41deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.2) 68.08%
  );
  height: 28px;
  width: fit-content;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font: 0.75rem/1rem Onest-SemiBold;
  align-items: center;
  display: flex;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const SaleStrip = styled.div`
  position: absolute;
  background: #ff4646;
  height: 32px;
  width: 130px;
  transform: rotate(-45deg);
  left: -32px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 0.875rem/1.125rem Onest-Bold;
  color: #ffffff;
`;
